module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-163383881-1","head":true,"exclude":["/preview/**","/do-not-track/me/too/"],"cookieDomain":"gastronomik.sk"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"showCaptions":false,"maxWidth":1200},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"Gastronomik","description":"Gastronomik je miesto, kde zažijete skutočne dobré jedlo. Blog o všetkom chutnom.","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"standalone","icon":"src/favicon.png"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
